<template>
  <Layout>
    <div class="noadscontainer">
      <div class="logo neo" />
      <img
        v-if="orientation !== 'landscape'"
        class="header"
        alt="Mythic Legends"
        :src="require('../../assets/images/ml_image.png')"
      >
      <h1 class="title">
        {{ content.noads_title }}
      </h1>
      <div class="na_text">
        <span v-html="content.noads_text" />
        <ExternalLink
          :url="o7policyUrl"
          :text="content.noads_text2"
        />
        <span v-html="content.noads_text3" />
      </div>
      <Footer
        module="noads"
        screen="noads"
      />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/Footer.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
    ExternalLink,
  },
  data() {
    return {
      content: {},
      orientation: '',
      o7policyUrl: '',
    }
  },
  created() {
    this.$compliance.initCollector('consent_noads')
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation

    // request Outfit7 policy url
    this.$compliance.privacyUrl('outfit7').then((pp) => {
      this.o7policyUrl = pp
    })
  },
}
</script>

<style lang="scss">
.noadscontainer {
  background: #241720;
  width: 100%;
  height: 100%;

  .logo {
    width: 52px;
    height: 34px;
    max-width: 130px;
    margin: 0 auto 0 auto;
    &.neo {
      width: 88px;
      background-image: url('../../assets/images/o7neo-logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .header {
    display: block;
    width: calc(100% - 100px);
    max-width: 460px;
    height: auto;
    margin: 0 auto;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    font-size: 1.8em;
    line-height: 1em;
    color: #f3bf76;
    text-align: center;
    text-transform: uppercase;
    @media screen and (min-width: 460px) {
      font-size: 2em;
    }
    @media screen and (min-width: 768px) {
      font-size: 3em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .na_text {
    padding: 0 0 10px 0;
    font-size: 0.8em;
    color: #eadacb;
    text-align: center;
    margin: 0 0 145px 0;
    .external_link {
      .span {
        color: #f3bf76;
        text-decoration: underline;
        text-transform: none;
      }
    }
    @media screen and (min-width: 375px) {
      font-size: 0.9em;
    }
    @media screen and (min-width: 460px) {
      font-size: 1em;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.4em;
      padding: 0 30px 20px 30px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8em;
    }
  }
  .footer {
    background: #241720;
    box-shadow: 0px -10px 16px 16px #241720;
    .global_consent_options .btn {
      color: #614c49;
      &.green {
        background-image: url('../../assets/images/button-green.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.grey {
        background-image: url('../../assets/images/button-sand.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      & span {
        margin-top: 0;
      }
    }
  }
  .external_link .span {
    color: #f3bf76;
  }
}
.landscape .noadscontainer .na_text {
  margin: 0;
}
.onehelsing.landscape .layout {
  & .noadscontainer {
    background-color: transparent;
    width: 100%;
    & .header {
      display: none;
    }
    & .title {
      font-size: 1.8em;
      font-weight: 400;
      color: #ffffff;
      @media screen and (min-width: 768px) {
        font-size: 2.2em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.5em;
      }
    }
    & .na_text {
      padding-bottom: 132px;
      font-weight: 400;
      color: #acb9d2;
      font-size: 1.1em;
      @media screen and (min-width: 1024px) {
        padding-bottom: 164px;
        font-size: 1.3em;
      }
    }
    & .external_link {
      & .span {
        text-transform: none;
        color: #D2C5AC;
      }
    }
    & .global_consent_options {
      position: relative;
      padding: 0;
      transform: translate(0, 0);
      display: flex;
      justify-content: center;
      & .btn {
        border-radius: 12px;
        color: #ffffff;
        border: 2px solid #000000;
        -webkit-text-stroke: 1px #000000;
        /* https://stackoverflow.com/questions/69253420/text-stroke-webkit-text-stroke-css-problem */
        paint-order: stroke fill;
        text-shadow: 1px 1px #000000;
        box-shadow: 2px 2px #000000;
        width: 220px;
        margin-bottom: 12px;
        margin-top: 0;
        @media screen and (min-width: 1024px) {
          border-radius: 16px;
          border: 4px solid #000000;
          text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 2px 2px 0 #000;
        }
        &.grey {
          background-image: none;
          background-color: #d3b486;
          margin-right: 24px;
        }
        &.green {
          background-image: none;
          background-color: #95C11F;
          margin-left: 24px;
        }
        & span {
          font-size: 1.2em;
          @media screen and (min-width: 1024px) {
            font-size: 1.6em;
          }
        }
      }
    }
    & .footer {
      background: #0A152A;
      box-shadow: 0px -10px 16px 16px #0A152A;
      width: 100%;
      position: fixed;
      & .pp_text {
        background-color: transparent;
        display: flex;
        justify-content: center;
        color: #ACB9D2;
        font-weight: 400;
        font-size: 1em;
        & > div:first-of-type::after {
          // Non breaking space as we do not want privacy policy text to take over 2 lines
          content: '\00A0';
        }
      }
    }
  }
}
.es .noadscontainer .title,
.pt .noadscontainer .title,
.de .noadscontainer .title,
.fr .noadscontainer .title,
.ru .noadscontainer .title,
.ja .noadscontainer .title,
.ko .noadscontainer .title {
  font-size: 1.2em;
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 5em;
  }
}
.es .noadscontainer .na_text,
.pt .noadscontainer .na_text,
.de .noadscontainer .na_text,
.fr .noadscontainer .na_text,
.ru .noadscontainer .na_text,
.ja .noadscontainer .na_text,
.ko .noadscontainer .na_text {
  font-size: 0.7em;
  @media screen and (min-width: 375px) {
    font-size: 0.8em;
  }
  @media screen and (min-width: 460px) {
    font-size: 0.9em;
  }
}
.es .noadscontainer .pp_text,
.pt .noadscontainer .pp_text,
.de .noadscontainer .pp_text,
.fr .noadscontainer .pp_text,
.ru .noadscontainer .pp_text,
.ja .noadscontainer .pp_text,
.ko .noadscontainer .pp_text {
  font-size: 0.9em;
  @media screen and (min-width: 768px) {
    font-size: 1.4em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.8em;
  }
}
</style>
